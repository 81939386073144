import React from "react";
import { Box } from "@chakra-ui/react";
import { Footer, Header, SEO } from "../components";
import { BulletPoints, ServicesSummary } from "../components/Services";

const ServicesPage: React.FC = () => (
	<Box bg="background" overflowX="hidden">
		<SEO
			title={"LegioSoft | Services"}
			description={
				"LegioSoft offers long-term cooperation with flexible SCRUM teams, expert software development, and engineering, using multiple technologies for your business needs."
			}
			keywords={[
				"software development",
				"web development",
				".net core development",
				"node js development",
				"react development",
				"mobile development",
				"react native",
			]}
		/>
		<Header />
		<ServicesSummary />
		<BulletPoints />
		<Footer />
	</Box>
);

export default ServicesPage;
