import React from "react";
import { Box, Heading, Text, SimpleGrid, HStack, Container } from "@chakra-ui/react";
import CheckmarkIcon from "../../icons/checkmark.svg";
import ServicesWaves2 from "../../images/waves_services_2.png";
import ServicesWaves3 from "../../images/waves_services_3.png";
import { servicesPoints } from "../../constants";
import { motion } from "framer-motion";
import ServicesRed from "../../images/services_red.png";
import ServicesBlue2 from "../../images/services_blue_2.png";

const MotionBox = motion(Box);

export const BulletPoints: React.FC = () => (
	<Box mt="200px" mb="100px" position="relative">
		<Container maxW="container.xl" zIndex={10} position="inherit">
			<SimpleGrid columns={{ base: 1, md: 2 }} columnGap="50px" rowGap="100px">
				{servicesPoints.map(elem => (
					<Box key={elem.title}>
						<Heading color="white" fontSize="40px" lineHeight="50px">
							{elem.title}
						</Heading>
						<Text color="white" fontSize="16px" mt="30px" mb="60px">
							{elem.description}
						</Text>
						{elem.items.map((item, index) => (
							<HStack key={index} mb="25px">
								<CheckmarkIcon />
								<Text color="white" fontSize="16px">
									{item}
								</Text>
							</HStack>
						))}
					</Box>
				))}
			</SimpleGrid>
		</Container>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 4 }}
			position="absolute"
			w="100%"
			h="1200px"
			backgroundImage={ServicesWaves2}
			backgroundRepeat="no-repeat"
			top="-200px"
		/>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 4 }}
			position="absolute"
			top="800px"
			w="100%"
			h="1000px"
			backgroundImage={ServicesWaves3}
			backgroundRepeat="no-repeat"
			backgroundPosition="right"
		/>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 2 }}
			w="1000px"
			h="1000px"
			backgroundImage={ServicesRed}
			position="absolute"
			top="-200px"
			left="200px"
			zIndex={1}
		/>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 2 }}
			w="1000px"
			h="1000px"
			backgroundImage={ServicesBlue2}
			position="absolute"
			bottom="-200px"
			right="0"
			zIndex={1}
		/>
	</Box>
);
