import React from "react";
import { Box, Text, Center, Button, Heading, Container, Stack } from "@chakra-ui/react";
import { LinksStrip } from "../index";
import { StaticImage } from "gatsby-plugin-image";
import ServicesWaves1 from "../../images/waves_services_1.png";
import { colors } from "../../constants";
import { navigate } from "gatsby";
import { motion } from "framer-motion";
import ServicesGreen from "../../images/services_green.png";
import ServicesBlue from "../../images/services_blue.png";
import ServicesPurple from "../../images/services_purple.png";

const MotionBox = motion(Box);

export const ServicesSummary: React.FC = () => (
	<Box position="relative">
		<Container maxW="container.xl" zIndex={10} position="inherit">
			<LinksStrip active="Services" />
			<Heading as="h1" color="white" fontSize={{ base: "40px", md: "60px" }}>
				Our Services
			</Heading>
			<Box
				border={`2px solid ${colors.pointsBorder}`}
				bg="background"
				borderRadius="50px"
				p={{ base: "30px", md: "100px" }}
				mt="60px"
			>
				<Stack
					direction={{ base: "column", md: "row" }}
					align="baseline"
					spacing="25px"
					mb="40px"
				>
					<Box flex={1}>
						<StaticImage src="../../images/shield_green.png" alt="Shield icon" />
						<Text mt="15px" color="white" fontSize="16px">
							We take all of the technical concerns off your shoulders.
						</Text>
					</Box>
					<Box flex={1}>
						<StaticImage
							src="../../images/question_green.png"
							alt="Question mark icon"
						/>
						<Text mt="15px" color="white" fontSize="16px">
						We're continually right here to create and enhance the product from preliminary evaluation to scaling. 
						So we take all of the technical concerns off your shoulders.
						</Text>
					</Box>
					<Box flex={1}>
						<StaticImage src="../../images/debug_green.png" alt="Debug icon" />
						<Text mt="15px" color="white" fontSize="16px">
							Our services cover each and every component of product development.
						</Text>
					</Box>
				</Stack>
				<Stack
					align="baseline"
					spacing="25px"
					mb="40px"
					direction={{ base: "column", md: "row" }}
				>
					<Box flex={1}>
						<StaticImage src="../../images/lightbulb_green.png" alt="Lightbulb icon" />
						<Text mt="15px" color="white" fontSize="16px">
							We've created from scratch, launched, and helped to scale over 20
							digital solutions, and understand properly a way to construct one which
							stands out.
						</Text>
					</Box>
					<Box flex={1}>
						<StaticImage src="../../images/group_green.png" alt="Group icon" />
						<Text mt="15px" color="white" fontSize="16px">
							We prefer to set up long-term relationships with our clients, dive
							deeper into projects, and make contributions now no longer best through
							coding, however through thinking collectively in terms of better
							solutions.
						</Text>
					</Box>
				</Stack>

				<Box>
					<StaticImage src="../../images/bullseye_green.png" alt="Target icon" />
					<Text color="white" fontSize="16px" mt="15px">
						Our strategies are constructed regarding our customers' wishes to deliver the
                        software challenge outcomes as expected. We've
                        managed to win various pre-sales, operations, quality, services delivery,
                        and human resources for the last six years. Each of those core enterprise strategies and their
                        capabilities is executed with transparency, project goal,
                        and customer satisfaction in mind.
					</Text>
				</Box>
				<Center mt="60px">
					<Button
						variant="primary"
						textTransform="uppercase"
						onClick={() => navigate("/contacts")}
					>
						Contact now
					</Button>
				</Center>
			</Box>
		</Container>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 4 }}
			position="absolute"
			w="100%"
			h="1300px"
			top="-170px"
			backgroundImage={ServicesWaves1}
			backgroundRepeat="no-repeat"
			backgroundPosition="right"
			zIndex={0}
		/>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 2 }}
			w="1000px"
			h="1000px"
			backgroundImage={ServicesBlue}
			position="absolute"
			top="100px"
			left="10vw"
			zIndex={1}
		/>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 2 }}
			w="1000px"
			h="1000px"
			backgroundImage={ServicesGreen}
			position="absolute"
			top="-150px"
			left="40vw"
			zIndex={1}
		/>
		<MotionBox
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			viewport={{ once: true }}
			transition={{ duration: 2 }}
			w="1000px"
			h="1000px"
			backgroundImage={ServicesPurple}
			position="absolute"
			bottom="-350px"
			left="50vw"
			zIndex={1}
		/>
	</Box>
);
